import axios from './secIndex'

// 已绑定车辆信息
export const GetCarBoundInfo = params => {
  return axios.request({
    url: '/manage/vehicle/vehicles',
    method: 'get',
    params
  })
}
// 添加车辆绑定信息ヾ(*´▽‘*)ﾉ
export const AddVehicle = data => {
  return axios.request({
    url: '/manage/vehicle/vehicle_bind',
    method: 'post',
    data
  })
}
// 获取车量信息
export const GetCarInfo = params => {
  return axios.request({
    url: '/manage/vehicle/vehicle_data',
    method: 'get',
    params
  })
}
// 删除已有车辆信息
export const DelVehicle = data => {
  return axios.request({
    url: '/manage/vehicle/vehicle_del',
    method: 'post',
    data
  })
}
// 获取车量信息类别
export const GetCarChooseInfo = params => {
  return axios.request({
    url: '/manage/vehicle/vehicle_categories',
    method: 'get',
    params
  })
}

// 街道id
export const GetCarStreetInfo = params => {
  return axios.request({
    url: '/manage/vehicle/areas',
    method: 'get',
    params
  })
}

// ❤❤❤❤❤❤结束
// 添加一个围栏
export const AddFence = data => {
  return axios.request({
    url: '/manage/fence/fence_add',
    method: 'post',
    data
  })
}
// 获取所有围栏
export const GetFenceList = params => {
  return axios.request({
    url: '/manage/fence/fence_get',
    method: 'get',
    params
  })
}
// 获取小区列表
export const GetcommunitiesList = params => {
  return axios.request({
    url: '/manage/vehicle/communities',
    method: 'get',
    params
  })
}
// 删除小区围栏
export const DelFence = data => {
  return axios.request({
    url: '/manage/fence/del',
    method: 'post',
    data
  })
}
