import axios from 'axios'
import { secbaseURL } from '@/config'
import qs from 'qs'
import store from '../store/index'
import { getLocal } from './local'
import { Loading } from 'element-ui';

let loadingInstance = null
class HttpRequest {
  constructor(secbaseUrl = secbaseURL) {
    this.secbaseUrl = secbaseUrl
    this.queue = {}
  }
  getInsideConfig() {
    const config = {
      baseURL: this.secbaseUrl,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }
    return config
  }
  // 定义heasers
  // 拦截器
  // 请求拦截器
  interceptors(instance) {
    instance.interceptors.request.use(

      config => {
        loadingInstance = Loading.service({fullscreen:true})
        const tokenf = getLocal('token')
        const token = store.state.login.UserToken
        // 添加全局loading
        // 判断token是否过期结合user.js方法authorization
        // config.headers['Authorization']= getToken()
        if (config.url !== '/user/upload') {
          config.data = qs.stringify(config.data) // 转为formdata数据格式
        }
       
        if (token) {
          // 判读token是否存在

          if (config.url === '/information') {
            config.headers.token = tokenf
          } else {
            config.headers.token = token
          }
        }

        return config
      },
      error => {
        // 错误
        return Promise.reject(error)
      }
    )
    // 响应拦截器
    instance.interceptors.response.use(
      res => {
        loadingInstance.close()
        // console.log(res)
        return res
      },
      error => {
        // 错误
        return Promise.reject(error)
      }
    )
  }
  request(options) {
    const instance = axios.create()

    options = Object.assign(this.getInsideConfig(), options)

    this.interceptors(instance, options.url)
    return instance(options)
  }
}

export default HttpRequest
